require(['jquery'], function($) {
	// Hide the "Welcome" section for logged in users. https://commercebuild.mavenlink.com/workspaces/35389695/#tracker/888999099
	if ($('.welcome-msg-user').length) {
        $('.sat-midsection').hide();
    }
	// 
	if (window.location.href.indexOf("/user/") > -1) {
        $(".mh-filters-btn").hide();
        $(".mh-account-btn").hide(); //
    }
	// Add make a payment URL
	if (window.location.hostname !== "parts-eu.satelliteindustries.com") { // payment of open invoices isn't enabled on the EU site.
		$(".my-account-account_menu_orders").after('<li class="my-account-sat-pay"> <a href="/user/transhistory/openinvc">Make a Payment</a> </li>');
	}
	// Fix compare class having incorrect classes.
    $('.item-compare-widgetgrid12-0').removeClass('item-compare-widgetgrid12-0').addClass('item-compare-widget grid12-0');
    $('.item-compare-widgetgrid12-6').removeClass('item-compare-widgetgrid12-6').addClass('item-compare-widget grid12-6');
	// Hide product details description if it's empty
	let productDescText = $(".module-product-details__desc").text().trim();
	if (productDescText == '') {
			$(".module-product-details__desc").hide();
	} 
	// Prop65
	if (window.location.hostname !== "parts-eu.satelliteindustries.com") { // prop65 shouldn't show for the EU
	 	var warningText = '*These products may contain chemicals known to the State of California to cause cancer and/or birth defects or other reproductive harm. For more information go to <a style="text-decoraton:underline" href="https://www.P65Warnings.ca.gov">www.P65Warnings.ca.gov</a>';
  		$('<div class="prop65"></div>').html(warningText).appendTo('.sat-product-details .module-product-details');
	}
	// Remove (1)  from the product details
	// Hide the "label" element in ".module-product-details__uom"
	$(".module-product-details__uom label").remove();
	$(".module-product-details__uom .item_qoh_conversion_unit").remove();
	// Replace all parentheses and remove white space in "#unit-messure-wrapper"
	$("#unit-messure-wrapper").text(function(_, txt) {
	  return txt.replace(/[()]/g, "").replace(/\s+/g, "");
	});
	// Iterate through items and modify how the Unit of Measure appears
	$('.item .module-category-product-listing').each(function() {
	    var $item = $(this); // Cache the current .item
	
	    // Check if current .item's .price-box contains .old-price
	    if ($item.find('.price-box .old-price').length > 0) {
	      // Hide .price-per-unit within this .item
	      $item.find('.price-box .price-per-unit').hide();
	
	      // Store the value of .price-per-unit in a variable
	      var priceUom = $item.find('.price-box .price-per-unit').text();
	      var newText = priceUom.replace('per', '');
	      $item.find('.price-box .price-per-unit').text(newText);
	
	      // Add priceUom to the end of .old-price and .special-price within this .item
	      $item.find('.price-box .old-price').append(' ' + newText);
	      $item.find('.price-box .special-price').append(' ' + newText);
	    } else {
	      // Remove the word "per" from .price-per-unit within this .item
	      var text = $item.find('.price-box .price-per-unit').text();
	      var newText = text.replace('per', '');
	      $item.find('.price-box .price-per-unit').text(newText);
	    }
	  });
	// Remove quantity box if no add to cart button
	if ($('.add-to-cart').text().trim() === '') {
    	$('.module-product-details__qtyInput').hide();
	}
	// Favorites spelling
	// Get all elements with the specified class name
	const spanElements = document.getElementsByClassName('v-center span-title');
	if ( spanElements ) {
		// Iterate over the elements and replace all instances of "Favourites" with "Favorites"
		for (let i = 0; i < spanElements.length; i++) {
		  const spanElement = spanElements[i];
		  spanElement.innerHTML = spanElement.innerHTML.replace(/Favourites/g, 'Favorites');
		}
	}
	
	// Get the element by its class name
	const iconElement = document.querySelector('.fa-heart');
	if (iconElement) {
		// Update the text content
		iconElement.parentElement.parentElement.title = "Remove From Favorites";
		                                        
		// Update the title attribute
		iconElement.parentElement.parentElement.title = "Add To Favorites";
	}
    // Remove sidebar if empty
    let sideBarWrapper = $(".sidebar-wrapper");
    let sideBarText = sideBarWrapper.text().trim();
    let productSearchContainer = $(".product-search-container");
    if (sideBarText.length === 0 && productSearchContainer.length > 0) {
        sideBarWrapper.hide();
        $(".main-container .grid-column-wrapper").removeClass("grid9-12").addClass("grid12-12");
        $(".sat-cat-filters").css("height", "auto");
    }
    // Change breadcrumb separator
    $(".breadcrumb-separator").text('>');
    // remove dollar sign if no pricing
    setTimeout(function() {
        $('.price').each(function() {
            if ($(this).text().includes('Contact for pricing')) {
                $(this).find('span').remove();
                $(this).parent().parent().find('.price-per-unit').remove();
            }
        });
    }, 500);

    // Change price tag
    $('.quad-section-hd .product_pricetag').each(function() {
        var price = $(this).text();
        price = price.replace('US$', '$');
        $(this).replaceWith('<span>' + price + '</span>');
    });
    // Add spacing to currency when used
    $('.product_pricetag').each(function() {
        var price = $(this).text();
        if ($(this).parent().text().includes('Contact') == false ) {
        	if (price.includes('US$')) {
		        price = price.replace('US$', '$');
		        $(this).text(price);
		        $(this).parent().append(' USD');
        	}
        	if (price.includes('CA$')) {
		        price = price.replace('CA$', '$');
		        $(this).text(price);
		        $(this).parent().append(' CAD');
        	}
        }
    });
    // Remove extraneous spacing in price
    $('.price').each(function() {
    	var newPrice = $(this).text().trim();
    	$(this).text(newPrice);
    });
    
    // Change sup to span
   $('.product_pricetag').each(function() {
	    if ($(this).is('sup')) {
	      const content = $(this).html();
	      const newElement = $('<span/>').html(content).addClass('product_pricetag');
	      $(this).replaceWith(newElement);
	    }
	  });

    // Move price box on owl slider
    /*
    let productDetail = $(".product-shop");
    if (productDetail.length === 0) {
	    setTimeout(function() {
	        $('.itemslider .item').each(function() {
	            var priceBox = $(this).find('.price-box');
	            var productImageWrapper = $(this).find('.product-image-wrapper');
	            priceBox.detach().insertAfter(productImageWrapper);
	        });
	    }, 500); // Delay of 500 milliseconds
    }
    */
    // Home item codes change to #
    $('.home .item-code').each(function() {
        var text = $(this).text();
        text = text.replace(/Item: /g, '#');
        $(this).text(text);
    });
    /////
    $('.display-mode-list .item .module-category-product-listing').each(function() {
        var $this = $(this); // Reference to the current .module-category-product-listing element

        // Create the .cat-col class elements
        $this.append('<div class="satellite-cat-col"></div><div class="satellite-cat-col"></div><div class="satellite-cat-col"></div>');

        // Find and move module-category-product-listing__productImage into the first .cat-col class element
        $this.find('.module-category-product-listing__productImage').appendTo($this.find('.satellite-cat-col:first'));

        // Find and move .module-category-product-listing__productDesc and .module-category-product-listing__productSecDec into the first .cat-col class element
        $this.find('.module-category-product-listing__productDesc, .module-category-product-listing__productSecDec').appendTo($this.find('.satellite-cat-col').eq(1));

        // Find and move .module-category-product-listing__price and .module-category-product-listing__actionButtons into the second .cat-col class
        $this.find('.module-category-product-listing__price, .module-category-product-listing__actionButtons').appendTo($this.find('.satellite-cat-col:last'));
    });
    //// 

    // Add hot deals image to background so it can be more mobile friendly
    function updateHotDealsQuad() {
    	console.log("Update Hot Deals Module Size");
	    $('.quad-section .left').each(function() {
	        var imageUrl = $(this).find('img').attr('src');
	        //$(this).find('img').hide();
	        $(this).find('.item').css('background-image', 'url(' + imageUrl + ')');
	    });
    }
    updateHotDealsQuad();
    window.addEventListener('resize', function() {
            setTimeout(updateHotDealsQuad, 200);
    });
    // Hide .b2c elements on B2B page
    window.addEventListener('load', function() {
        var isPublicElement = document.querySelector('.isPublic');

        if (!isPublicElement) {
            var b2cElements = document.querySelectorAll('.b2c');

            b2cElements.forEach(function(element) {
                element.style.display = 'none';
            });
        }
    });
    // Change "Amp" text to ampserand because you can't input this in the text heading (bug)
    $('.section-title').each(function() {
        var text = $(this).text();
        text = text.replace(/amp/g, '&');
        $(this).text(text);
    });
    // Add faux borders in quad carousel.
    $(".quad-section-hd .itemslider-wrapper").append('<div class="line-vertical"></div> <div class="line-horizontal"></div>');
    // Call setWidthHeight and debounce
    let isQuadSectionExist = $(".quad-section").length;
    console.log("isQuad: " + isQuadSectionExist);
    if (isQuadSectionExist > 0) {
        var debouncedSetWidthHeight = debounce(setWidthHeight, 250);

        $(document).on("click", ".quad-section *", function() {
            debouncedSetWidthHeight();
        });
        // Listen for browser resizing and fire setWdithHeight function
        window.addEventListener('resize', function() {
            setTimeout(setWidthHeight, 500);
        });
        // Listen for browser load and fire setWidthHeight
        window.addEventListener('load', function() {
            setTimeout(setWidthHeight, 500);
            setTimeout(setWidthHeight, 2500); // for firefox
        });
        // Run on scroll
        $(window).on('scroll', function() {
        	debouncedSetWidthHeight();
        	updateHotDealsQuad();
        });
    }
    // Readjust sizes on clicks to prevent distortions
    function debounce(func, wait) {
        var timeout;
        return function() {
            var context = this,
                args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                func.apply(context, args);
            }, wait);
        };
    }
    // Function set width height of quad blocks using the carousel
    function setWidthHeight() {
        if (window.innerWidth > 1100) {
            console.log("resize firing!");
            var stageOuterWidth = document.querySelector('.quad-section-hd .owl-stage-outer').offsetWidth;
            var itemWidth = Math.round(stageOuterWidth / 2) - 1;

            var stageOuterHeight = document.querySelector('.quad-section-hd .owl-stage-outer').offsetHeight;
            var itemHeight = Math.round(stageOuterHeight / 2)-4;
            
            //$(".quad-section-hd .cloned").remove();
            
            var items = document.querySelectorAll('.quad-section-hd .owl-carousel .owl-item');
            items.forEach(function(item) {
                item.style.width = itemWidth + 'px';
                item.style.height = itemHeight + 'px';
            });
        }
    }
    // Add catalogs icon
    $(".catalogs .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/catalogs.png"></span>');
    // Add handwash icon
    $(".handwash .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/handwash.png"></span>');
    // Add portable toilet parts icon.
    $(".portable .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/restrooms.png"></span>');
    // Add consumables icon.
    $(".consumables .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/consumables-new.png"></span>');
    // Add deodorizers icon.
    $(".deodorizers .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/deodorizers.png"></span>');
    // Add popular parts icon to home page as well as product detail
    $(".popular-parts .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/popular-star.png"></span>');
    $(".module-product-details__customerWhoBought .section-title").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/popular-star.png"></span>');
    // Add user svg
    $(".sat-midsection .section2 .section-header").prepend('<span class="sat-icon-img"><img src="https://commercebuild-themes.mysagestore.com/static/satellite/images/new_user.png"></span>');
    // Add carousel arrows 
    setTimeout(function() {
        $('.owl-controls .owl-prev').append('<i class="fa fa-chevron-left fa-2x"></i>');
        $('.owl-controls .owl-next').append('<i class="fa fa-chevron-right fa-2x"></i>');

    }, 500);

    // Select the ul element
    var $ul = $(".module-user-links ul.links");

    // Select the individual li elements
    var $logout = $ul.find(".link-logout").parent();
    var $admin = $ul.find(".link-admin").parent();
    var $account = $ul.find(".link-account").parent();
    var $register = $ul.find(".link-register").parent();
    var $login = $ul.find(".link-login").parent();

	if (window.location.hostname !== "parts-eu.satelliteindustries.com") { // payment of open invoices isn't enabled on the EU site.
	    // Create an additional li element for the SVG
	    var isPublicElement = $(".isPublic").length;
		var paymentLink;
		
		if (isPublicElement > 0) {
		    paymentLink = 'https://www.satelliteindustries.com/online_payment/';
		} else {
		    paymentLink = 'https://parts.satelliteindustries.com/user/transhistory/openinvc';
		}
		
		if (window.location.hostname === "parts-eu.satelliteindustries.com") {
			paymentLink = "/user/transhistory/openinvc";
		}
		
	    var $svgLi = $('<li class="link-svg"></li>');
	    $svgLi.append('<a href="' + paymentLink + '" target="_blank"><svg style="vertical-align:middle" width="50" height="25" viewBox="0 0 250 100"> <path d="M26.5,60l2.6-8h5c0.3,1.9,0.2,9.5,8.4,9.6c2.5-0.2,6.8-0.8,7.7-6.2c-0.3-2-1.4-3.6-7.6-6.8c-3.8-1.9-11.4-5.9-9.1-13.8 c1.7-3.7,4.5-9.8,20.5-9.5c3.4,0.2,10.5,1.4,13.1,2.6l-2,6.5l-5.5-0.5c-0.6-1.9-2.3-8.2-9.2-6.5c-3,0.8-6.7,4-4.3,7.9 c1.9,2,4.5,3.6,9.4,6.2c4.3,2.1,10.2,6.7,7.2,13.5c-3,4.7-9,8.9-19.9,8.7C38.8,63.9,32.7,63.5,26.5,60z"></path> <path d="M89.1,44.6c0,22.9-21,37.7-42.4,37.4C25.5,82,5,67.2,5,44.6c0.3-31.8,37.6-46,63.4-32.5C80.7,18.1,89.3,30.7,89.1,44.6 L89.1,44.6z M76.6,44.6c0-12.2-5.6-25.4-16.9-31.4c-12.2-6.7-28-2.8-36.1,8.3c-5,6.6-7.5,14.9-7.5,23.1c0,12.5,5.8,25.8,17.4,31.8 c8,4.2,18.3,4.2,26.2-0.2C71.1,70,76.6,56.9,76.6,44.6L76.6,44.6z"></path> <path d="M119.8,15.4h14.9c16.3,0,25.4,8.8,25.4,22.5c0,13.6-9.1,22.5-25.4,22.5H129v14.1h-9.2V15.4z M134.3,52.4 c11.6,0,16.3-5.5,16.3-14.4c0-9-4.8-14.4-16.3-14.4H129v28.9H134.3z"></path> <path d="M161.5,56.2c0-11,7.3-19,16.9-19c6.2,0,10.3,3,12.5,7.5l-0.1-6.7h8.8v36.5h-8.8l0.1-6.8c-2.1,4.3-6.5,7.6-12.6,7.6 C168.9,75.3,161.5,67.2,161.5,56.2z M190.9,56.3c0-6.6-3.9-11.2-10.3-11.2c-6.3,0-10,4.5-10,11.1c0,6.8,3.7,11.4,10,11.4 C187,67.6,190.9,63,190.9,56.3z"></path> <path d="M221.5,71.2l-15-33.2h9.7l7.7,18.3c1.2,2.8,2.1,7.6,2.1,7.6s0.8-4.8,2-7.6l7.8-18.3h9.1L221.1,93h-9L221.5,71.2z"></path> </svg></a>'); // Add your SVG content here
	}
	var $satInd;
	if (window.location.hostname == "parts-eu.satelliteindustries.com") {
    	$satInd = $('<li class="sat-ind-link"><a href="https://www.satelliteindustries.co.uk/" target="_blank">Satellite Industries</a></li>');
	} else if (window.location.hostname == "parts.satelliteindustries.com") {
		$satInd = $('<li class="sat-ind-link"><a href="https://www.satelliteindustries.com/" target="_blank">Satellite Industries</a></li>');
	}
    if (window.innerWidth > 960) {
        // Change the text of the "Account" link
        $account.find("a").text("My Account");

        // Rearrange the li elements
        $ul.empty().append($admin).append($satInd).append($svgLi).append($register).append($login).append($account).append($logout);

        // Add vertical bar
        $('.links li').each(function() {
            if (!$(this).text().includes('Log')) {
                $(this).after('<div class="vertical-bar"></div>');
            }
        });
    } else {
        $(".logo-wrapper").after('<div class="link-svg-div hide-above-960"><a href="https://www.satelliteindustries.com/online_payment/" target="_blank"><svg style="vertical-align:middle" width="75" height="25" viewBox="0 0 250 100"> <path d="M26.5,60l2.6-8h5c0.3,1.9,0.2,9.5,8.4,9.6c2.5-0.2,6.8-0.8,7.7-6.2c-0.3-2-1.4-3.6-7.6-6.8c-3.8-1.9-11.4-5.9-9.1-13.8 c1.7-3.7,4.5-9.8,20.5-9.5c3.4,0.2,10.5,1.4,13.1,2.6l-2,6.5l-5.5-0.5c-0.6-1.9-2.3-8.2-9.2-6.5c-3,0.8-6.7,4-4.3,7.9 c1.9,2,4.5,3.6,9.4,6.2c4.3,2.1,10.2,6.7,7.2,13.5c-3,4.7-9,8.9-19.9,8.7C38.8,63.9,32.7,63.5,26.5,60z"></path> <path d="M89.1,44.6c0,22.9-21,37.7-42.4,37.4C25.5,82,5,67.2,5,44.6c0.3-31.8,37.6-46,63.4-32.5C80.7,18.1,89.3,30.7,89.1,44.6 L89.1,44.6z M76.6,44.6c0-12.2-5.6-25.4-16.9-31.4c-12.2-6.7-28-2.8-36.1,8.3c-5,6.6-7.5,14.9-7.5,23.1c0,12.5,5.8,25.8,17.4,31.8 c8,4.2,18.3,4.2,26.2-0.2C71.1,70,76.6,56.9,76.6,44.6L76.6,44.6z"></path> <path d="M119.8,15.4h14.9c16.3,0,25.4,8.8,25.4,22.5c0,13.6-9.1,22.5-25.4,22.5H129v14.1h-9.2V15.4z M134.3,52.4 c11.6,0,16.3-5.5,16.3-14.4c0-9-4.8-14.4-16.3-14.4H129v28.9H134.3z"></path> <path d="M161.5,56.2c0-11,7.3-19,16.9-19c6.2,0,10.3,3,12.5,7.5l-0.1-6.7h8.8v36.5h-8.8l0.1-6.8c-2.1,4.3-6.5,7.6-12.6,7.6 C168.9,75.3,161.5,67.2,161.5,56.2z M190.9,56.3c0-6.6-3.9-11.2-10.3-11.2c-6.3,0-10,4.5-10,11.1c0,6.8,3.7,11.4,10,11.4 C187,67.6,190.9,63,190.9,56.3z"></path> <path d="M221.5,71.2l-15-33.2h9.7l7.7,18.3c1.2,2.8,2.1,7.6,2.1,7.6s0.8-4.8,2-7.6l7.8-18.3h9.1L221.1,93h-9L221.5,71.2z"></path> </svg></a></div>');
    }

    // Change mini cart icon
    $('.item-min-cart .icon').removeClass('i-cart').addClass('fa fa-shopping-cart');
    // Add search icon 
    $('.module-search .form-search button').html('<i class="fa fa-search"></i>');
    // On click of search icon
    $('.module-search .form-search button').on('click', function(e) {
        let searchValue = $("#search").val().trim();
        console.log(searchValue);
        if (searchValue.length == 0) {
            e.preventDefault(); // Prevent default button action
            $('#search').animate({
                'opacity': 'toggle'
            }, 'slow'); // Toggle the visibility
        }
    });

});